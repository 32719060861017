<template>
  <span>
    <template v-for="(content, i) in template">
      <template v-if="content === null">
        <br v-if="linebreak" :key="'cr'+i"/>
      </template>
      <span
        :key="'entity'+i"
        v-else-if="typeof content === 'object'"
        class="entity" :class="content.entity"
        :data-entity="content.entity"
        :data-field="content.field"
      >
        {{getTagValue(content)}}
      </span>
      <template v-else-if="typeof content === 'string'">{{content}}</template>
    </template>
  </span>
</template>
<script>
import Candidate from '/user/candidate/candidate.entity.js'
import tagToText from '/snippet/text.js'
import Conciliation from '/interview/interview.js'

export default {
  name: 'hippolyte.snippet.text',
  props: {
    template: Array,
    candidate: Candidate,
    conciliation: Conciliation,
    linebreak: {
      type: Boolean,
      default () {
        return true
      }
    }
  },
  data () {
    return {
      recruiter: this.conciliation?.recruiter || null,
      search: this.conciliation?.search || null
    }
  },
  methods: {
    getTagValue (tag) {
      return tagToText(tag, { candidate: this.candidate, recruiter: this.recruiter, search: this.search })
    }
  }
}
</script>
<style lang="stylus">
@require '../colors'
pill($color)
  background-color lighten($color, 70%)
  color $color
  border 1px $color solid

.entity
  border-radius 5px
  padding-left 5px
  color white
  padding-right 5px
  &[data-entity="Account"][data-field="lastname"]
    pill($color-azur, 70%)
  &[data-entity="Account"][data-field="firstname"]
    pill($color-yellow)
  &[data-entity="Candidat"][data-field="firstname"]
    pill($color-peach)
  &[data-entity="Candidat"][data-field="lastname"]
    pill($color-iceberg)
  &[data-entity="Candidat"][data-field="email"]
    pill($color-azur, 70%)
  &[data-entity="Search"][data-field="trade"]
    pill($color-raisin)
  &[data-entity="Search"][data-field="title"]
    pill($color-metal)
  &[data-entity="Search"][data-field="location"]
    pill($color-dark-grey)
  &[data-entity="Recruiter"][data-field="company"]
    pill($color-connected)
</style>
