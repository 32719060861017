<template>
  <ats
    v-bind="$props"
    service="flatchr"
    :savingConf="savingConf"
    :credential="{ key: '', token: '' }"
    :cached="true"
    @input="onInput">
    <template v-slot:credential="{ credential }">
      <div>
        <label for="key">Clé entreprise</label>
        <input v-model="credential.key" id="key" name="key"/>
        <modified :enable="value.credential.key !== credential.key"/>
      </div>
      <div>
        <label for="token">Token</label>
        <input v-model="credential.token" id="token" name="token" />
        <modified :enable="value.credential.token !== credential.token"/>
      </div>
      <div>
        <label for="slug">Slug company</label>
        <input v-model="credential.slug" id="slug" name="slug" />
        <modified :enable="value.credential.slug !== credential.slug"/>
      </div>
    </template>
    <template v-slot:title="{ job }">{{ title(job) }}</template>
    <template v-slot:open="{ job }"><a class="button" :href="job.apply_url" target="_blank" title="ouvrir">👁️</a></template>
  </ats>
</template>
<script>
import Ats from './sdk-v3'
import Recruiter from '/user/recruiter/recruiter.entity'
import Search from '/requirement/entity'
import Modified from '/ats/modified'

export default {
  name: 'hippolyte.services.flatchr.config',
  components: { Ats, Modified },
  props: {
    value: Object,
    recruiter: Recruiter,
    search: Search,
    savingConf: Boolean
  },
  methods: {
    title (job) {
      return  [
        job.reference,
        job.title,
        job.address.parts.city
      ].filter(t => t).join(' - ')
    },
    onInput (event) {
      this.$emit('input', event)
    }
  }
}
</script>
