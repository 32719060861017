<template>
  <section>
    <h2>🍱 Job data #{{ job }}</h2>
    <template v-if="offer">
      <details open>
        <summary>Standardisé</summary>
        <pre class="code">{{ JSON.stringify(offer.job, null, 2) }}</pre>
      </details>
      <details>
        <summary>Brut</summary>
        <pre class="code">{{ JSON.stringify(offer.original, null, 2) }}</pre>
      </details>
    </template>
    <spinner v-else/>
  </section>
</template>

<script>
import Spinner from '/layout/spinner'

export default {
  name: 'hippolyte.services.ats.config.job.detail',
  components: {
    Spinner
  },
  props: {
    job: [String, Number],
    recruiter: String,
    service: String
  },
  watch: {
    job () {
      this.scan()
    }
  },
  data () {
    return {
      offer: null,
    }
  },
  methods: {
    async scan () {
      this.offer = await this.$socket.service(`${this.service}.jobs/get`, {
        recruiter: this.recruiter,
        job: this.job
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
@require '~/colors.styl'
.code
  font-size 0.8em
  background-color $color-torrent
  white-space pre-wrap
</style>
