class Join {
  constructor (opts = {}) {
    Object.assign(this, {
      entity: '',
      name: '',
      query: and(),
      fromField: '',
      toField: '',
      sort: [],
      raw: false
    }, opts)
  }

  send () {
    const acc = {
      entity: this.entity
    }
    if (!isEmpty(this.query)) {
      acc.query = this.query.toString()
    }
    if (this.name.length) {
      acc.name = this.name
    }
    if (this.fromField.length) {
      acc.fromField = this.fromField
    }
    if (this.toField.length) {
      acc.toField = this.toField
    }
    if (typeof this.limit === 'number') {
      acc.limit = this.limit
    }
    if (Array.isArray(this.fields) && this.fields.length) {
      acc.fields = this.fields
    }
    if (this.sort?.length) {
      acc.sorts = this.sort
    }
    return acc
  }

  from (field) {
    this.fromField = field
    return this
  }

  to (field) {
    this.toField = field
    return this
  }

  sorts (field, direction = 'asc', type = 'field') {
    if (type === 'function') {
      return this.sort.push({ type, direction, function: field })
    }
    if (type === 'field') {
      return this.sort.push({ type, direction, field })
    }
  }

}

class Relation extends Join {
  constructor (opts = {}) {
    super(opts)
    Object.assign(this, {
      relations: [],
      joins: [],
    }, opts)
  }

  join (...opts) {
    const res = opts.map(j => {
      const join = (j instanceof Join) ? j : new Join(j)
      this.joins.push(join)
      return join
    })
    return res.length > 1 ? res : res[0]
  }

  relation (...opts) {
    const res = opts.map(r => {
      const relation = (r instanceof Relation) ? r : new Relation(r)
      this.relations.push(relation)
      return relation
    })
    return res.length > 1 ? res : res[0]
  }

  send () {
    const acc = super.send()
    if (this.joins.length) {
      acc.joins = this.joins.map(j => j.send())
    }
    if (this.relations.length) {
      acc.relations = this.relations.map(r => r.send())
    }
    if (typeof this.limit === 'number') {
      acc.limit = this.limit
    }
    if (typeof this.offset === 'number') {
      acc.offset = this.offset
    }
    return acc
  }


  limits (to, offset) {
    this.limit = to
    if (typeof offset === 'number') {
      this.offset = offset
    }
  }
}

class Solr extends Relation {
  constructor (opts = {}) {
    super(Object.assign({}, {
      type: 'basic',
      raw: false,
      groups: [],
      parameters: [],
      filters: []
    }, opts))
  }

  filter (filter) {
    if (Array.isArray(filter)) {
      this.filters.push(...filter)
    } else {
      this.filters.push(filter)
    }
    return filter
  }

  group (...name) {
    this.groups.push(...name)
    return name
  }

  send () {
    return Object.assign(
      super.send(),
      { type: this.type, raw: this.raw },
      this.groups.length ? { groups: this.groups.map(name => ({ field: name })) } : {},
      this.sort.length ? { sorts: this.sort } : {},
      this.filters.length ? { filters: this.filters.map(f => f.toString()) } : {},
      { parameters: this.parameters },
      this.facets ? { facets: this.facets } : {}
    )
  }

  static async load (session, query) {
    return session.broker.service({
      name: 'entity_solr',
      verb: 'QUERY',
      headers: {
        'x-ywc-session': (await session.getSession()).id,
        'x-ywc-jwt': await session.getToken()
      }
    }, query instanceof Solr ? query.send() : query)
  }

  parameter (name, value, escape = true) {
    if (escape) {
      this.parameters.push({ name, value: `"${typeof value === 'undefined' ? name : value}"` })
    } else {
      this.parameters.push({ name, value: typeof value === 'undefined' ? name : value })
    }
  }
}

function isEmpty (c) {
  if (typeof c === 'undefined' || c === null) {
    return true
  }
  if (Array.isArray(c)) {
    return c.length === 0 || c.every(isEmpty)
  }
  if (c instanceof Cond) {
    return isEmpty(c.conds)
  }
  if (typeof c === 'string') {
    return c.length === 0
  }
  if (typeof c === 'number') {
    return false
  }
  if (typeof c === 'object') {
    return isEmpty(c.id)
  }
}

class Cond {
  constructor (...conds) {
    this.conds = conds
    this.prohibit = false
  }

  isEmpty () {
    return isEmpty(this)
  }

  prohibit () {
    this.prohibit = true
  }

  toString () {
    return this.conds
      .filter(c => !isEmpty(c))
      .map(c => (c instanceof Cond && this.conds.length > 1) ? `${c.isProhibit ? '-' : ''}(${c.toString()})` : c)
  }

  push (...conds) {
    this.conds.push(...conds)
    return this
  }
}

class And extends Cond {
  toString () {
    return this.isEmpty() ? null : super.toString().join(' AND ')
  }
}

class OR extends Cond {
  toString () {
    return this.isEmpty() ? null : super.toString().join(' OR ')
  }
}

class NOT extends Cond {
  toString () {
    return this.isEmpty() ? null : super.toString().join(' NOT ')
  }
}

function and (...props) {
  return new And(...props)
}

function or (...props) {
  return new OR(...props)
}

function not (...props) {
  return new NOT(...props)
}

function eq (...args) {
  switch (args.length) {
    case 0:
      return eq
    case 1:
      return b => eq(args[0], b)
    case 2:
      return `${args[0]}:"${escape(args[1])}"`
  }
}

function neq (...args) {
  switch (args.length) {
    case 0:
      return neq
    case 1:
      return b => neq(args[0], b)
    case 2:
      return `-${args[0]}:"${escape(args[1])}"`
  }
}

function search (opts, ...args) {
  switch (args.length) {
    case 0:
      return search
    case 1:
      return b => search(args[0], b)
    case 2:
      if (opts.insensitive) {
        return and(...args[1].split(' ')
          .filter(a => a !== null && a.length > 0)
          .map(a => {
            if (a.length > 0) {
              const newString = a.split('')
                .map(a => `(${a.toLowerCase()}|${a.toUpperCase()})`)
                .join('')
              return `${args[0]}:/.*${escape(newString)}.*/`
            }

            return `${args[0]}:/.*${escape(a)}.*/`
          })
        )
      } else {
        return and(...args[1].split(' ')
          .filter(a => a !== null && a.length > 0)
          .map(a => `${args[0]}:/.*${escape(a)}.*/`)
        )
      }
  }
}
function escape (token) {
  return typeof token === 'string' ? token.replace(/(\/[!*+&|()[]{}^~?:"])/g, '\\$1') : token
}

module.exports = {
  and, or, not, eq, neq, search, Solr
}
